<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row>
      <v-col cols="6">
        <v-card-title style="font-size:17px">
          {{ "Client files" }}
        </v-card-title>
        <v-data-table
          :headers="ticketing.downloadS3HeadersAppUser"
          :items="ticketing.documentS3ItemsAppUser"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :size="22"
              class="pt-1"
              @click="dispatchDownloadItem(item)"
            >
              mdi-download
            </v-icon>
            <delete
              :id="item.name"
              :disabled="requestIsDeleted"
              class="mb-1"
              @deleted="deleteS3Item(item)"
            />
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
        <v-card-title style="font-size:17px">
          {{ "Radarmeteo files" }}
        </v-card-title>
        <v-data-table
          :headers="ticketing.downloadS3HeadersRadarMeteo"
          :items="ticketing.documentS3ItemsRadarmeteo"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              :size="22"
              class="pt-1"
              @click="dispatchDownloadItem(item)"
            >
              mdi-download
            </v-icon>
            <delete
              :id="item.name"
              :disabled="requestIsDeleted"
              class="mb-1"
              @deleted="deleteS3Item(item)"
            />
          </template>
        </v-data-table>
        <!-- <div v-if="!requestIsDeleted">
          <v-btn
            v-if="this.$route.name === 'TicketingRequestList' || this.$route.name === 'TicketingRequestListComplete'"
            color="#0da344"
            style="color:white"
            small
            :disabled="disableAddDocument || ticketing.documentS3ItemsRadarmeteo.length == 0"
            @click="addDocument()"
          >
            Add document
          </v-btn>
        </div> -->
      </v-col>
    </v-row>
    <v-card-text
      v-if="showExtraInformations"
      class="d-flex justify-space-between mt-5"
    >
      <v-divider />
      <p class="mx-8 mt-n4 text-h6">
        Richiedi approfondimento manuale
      </p>
      <v-divider />
    </v-card-text>
    <v-file-input
      v-model="manualFile"
      :disabled="requestIsDeleted"
      accept=".pdf, .txt, .csv, .xlsx, .zip, .rar"
      class="mt-4"
      placeholder="Uploading files necessary for the request"
      prepend-inner-icon="mdi-file-document-outline"
      outlined
      multiple
      truncate-length="50"
      @change="confirmFile"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { S3_BUCKETS } from '@/constants.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    appUserTicketId: {
      type: String,
      default: ''
    },
    ticketingPath: {
      type: Boolean,
      default: false
    },
    request: {
      type: Object,
      default: null
    },
    requestType: {
      type: String,
      default: ''
    },
    isTabs: {
      type: Boolean,
      default: false
    },
    requestIsDeleted: {
      type: Boolean,
      default: false
    },
    showExtraInformations: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manualFile: [],
      overlay: false,
      disableAddDocument: true,
      document_s3_id: ''
    };
  },
  computed: {
    ...mapState(['ticketing', 'file_transfer', 'app_user'])
  },
  watch: {
    requestType(newRequestType) {
      this.ticketing.documentS3ItemsRadarmeteo.forEach(item => {
        this.$set(item, 'request_type', newRequestType);
      });
    }
  },
  async mounted() {
    if (this.$route.name === 'TicketingRequestList' ||
        this.$route.name === 'TicketingRequestListComplete') {
      this.ticketing.request_id = this.request.request_id;
    }

    if (this.$route.name === 'TicketingRequest') {
      this.ticketing.request_id = uuidv4();
    }

    this.overlay = true;

    await this.storeGetLinkedDocumentS3toTicketingRequest(this.ticketing.request_id);

    if (this.isTabs &&
      this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`] &&
      this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].length > 0) {

      this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].forEach(item => {
        this.ticketing.documentS3ItemsRadarmeteo.push(item);
        this.disableAddDocument = false;
      });
    }

    this.overlay = false;
  },
  methods: {
    ...mapActions([
      'deleteS3ItemAction',
      'downloadItem',
      'setUpload',
      'storeGetLinkedDocumentS3toTicketingRequest',
      'linkDocumentS3ToTicketingRequestAction',
      'deleteDocumentS3Action'
    ]),
    async confirmFile() {
      const extensions = ['pdf', 'txt', 'csv', 'xlsx', 'zip', 'rar'];

      for await (const file of this.manualFile) {
        const fileNamePart = file.name.split('.');
        const ext = fileNamePart[fileNamePart.length - 1];

        if (this.validTypes().includes(file.type) || extensions.includes(ext)) {
          this.document_s3_id = uuidv4();

          const params = {
            Bucket: S3_BUCKETS.application(),
            Key: this.ticketingPath ? `meteotrigger/${this.ticketing.request_id}/${file.name}` : `${this.$route.params.id}/${this.ticketing.request_id}/${file.name}`,
            Body: file
          };

          this.ticketing.documentS3ItemsRadarmeteo.push({
            file_name: file.name,
            document_s3_path: params.Key,
            request_id: this.ticketing.request_id,
            request_type: this.requestType,
            app_user_ticket_id: this.appUserTicketId,
            document_s3_id: this.document_s3_id,
            document_creator: 'radarmeteo'
          });
          this.disableAddDocument = false;
          await this.setUpload(params);

          if (this.isTabs) {
            this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].push({
              file_name: file.name,
              document_s3_path: params.Key,
              request_id: this.ticketing.request_id,
              app_user_ticket_id: this.appUserTicketId,
              document_s3_id: this.document_s3_id,
              document_creator: 'radarmeteo'
            });
          }

        } else {
          this.alert = true;
        }
      }
    },
    async addDocument() { // ref from father
      if (!this.disableAddDocument || this.ticketing.documentS3ItemsRadarmeteo.length > 0) {
        const addedOnly = this.ticketing.documentS3ItemsRadarmeteo.filter(storeItem =>
          !this.ticketing.storeDocumentS3Items.some(item =>
            storeItem.document_s3_id == item.document_s3_id
          )
        );

        this.$emit('new-document', addedOnly.length > 0);

        if (addedOnly.length > 0) {
          this.overlay = true;
          await this.linkDocumentS3ToTicketingRequestAction(addedOnly);
          this.overlay = false;
        }

        if (this.isTabs) {
          this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`] = [];
        }

        this.disableAddDocument = true;
      }
    },
    async deleteS3Item(item) {
      if (item.document_creator === 'radarmeteo') {
        this.ticketing.documentS3ItemsRadarmeteo.splice(this.ticketing.documentS3ItemsRadarmeteo.indexOf(item), 1);

        const isItemInTempDoc = this.isTabs &&
          this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].includes(item);

        if (isItemInTempDoc) {
          this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].splice(
            this.ticketing.tempDocS3Radarmeteo[`req${this.request.request_number}`].indexOf(item), 1
          );
        }

      } else {
        this.ticketing.documentS3ItemsAppUser.splice(this.ticketing.documentS3ItemsAppUser.indexOf(item), 1);
      }

      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: item.document_s3_path
      };

      this.overlay = true;
      await this.deleteS3ItemAction(params);

      if (this.$route.name === 'TicketingRequestList' || this.$route.name === 'TicketingRequestListComplete') {
        await this.deleteDocumentS3Action(item.document_s3_id);
      }

      this.overlay = false;
    },
    async dispatchDownloadItem(item) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: item.document_s3_path
      };

      await this.downloadItem(params);
      window.open(this.file_transfer.downloadLink);
    },
    validTypes() {
      const validTypes = {
        DOCUMENTS: {
          PDF: 'application/pdf',
          DOC: 'application/msword',
          DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          PPT: 'application/vnd.ms-powerpoint',
          PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        },
        SPREADSHEETS: {
          EXCEL: 'application/vnd.ms-excel',
          SPREADSHEET_EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        ARCHIVES: {
          ZIP: 'application/zip',
          COMPRESSED: 'application/x-zip-compressed',
          SEVEN_ZIP: 'application/x-7z-compressed',
          RAR: 'application/x-rar-compressed'
        },
        CSV: 'text/csv',
        TXT: 'text/plain'
      };

      const getAllValues = (obj) => {
        return Object.values(obj).reduce((values, value) => {
          if (typeof value === 'object') {
            return values.concat(getAllValues(value));
          }

          return values.concat(value);
        }, []);
      };

      return getAllValues(validTypes);
    }
  }
};
</script>
